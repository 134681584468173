import { Inventory } from '../../models/inventory.model';

import { sgdeApi } from '../index';

const inventoryApi = sgdeApi.enhanceEndpoints({ addTagTypes: ['Inventory'] }).injectEndpoints({
  endpoints: build => ({
    createInventory: build.mutation<void, { inventory: Inventory; reportId: number }>({
      query: updateInventoryItems => ({
        url: `/reports/${updateInventoryItems.reportId}/inventory/${updateInventoryItems.inventory.inventoryId}/items`,
        method: 'PUT',
        body: updateInventoryItems.inventory.items,
      }),
      invalidatesTags: ['Inventory'],
    }),
  }),
});

export const { useCreateInventoryMutation: useCreateInventory } = inventoryApi;
