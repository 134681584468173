import { Stroke, Style } from 'ol/style';

import { Feature } from 'ol';
import { LineString, Polygon } from 'ol/geom';
import { ReportTypes } from '../ReportType';
import OpenLayersMap from '../map/open-layers-map.model';
import SGDEFeature from './sgde-feature';

const customStylesByField: {
  [key: number]: {
    [key: string]: string;
  };
} = {
  85: {
    '2': '#ff7700',
    '3': '#ff7700',
    '4': '#ff1100',
  },
  78: {
    'Conducta apa': '#020473',
    'Conducta canalizare': '#785034',
    'Racord canalizare': '#785034',
    'Bransament apa': '#020473',
    Gaz: '#d4c200',
    'Energie Electrica': '#5659fc',
  },
};

function calculateSize(feature: Feature) {
  const geometry = feature.getGeometry();
  if (!geometry) {
    return 0;
  }

  let size = 0;
  if (geometry.getType() === 'Polygon' || geometry.getType() === 'MultiPolygon') {
    size = (geometry as Polygon).getArea();
  } else if (geometry.getType() === 'LineString' || geometry.getType() === 'MultiLineString') {
    size = (geometry as LineString).getLength();
  }
  return size;
}

function getCustomStyle(map: OpenLayersMap, feature: SGDEFeature, defaultToLayer: boolean = true): Style | undefined {
  const reportType = feature.reportTypeId as ReportTypes;
  let layerStyle = map.measurementsLayersMap.get(reportType)?.getStyle() as Style;
  layerStyle = layerStyle.clone();
  const customStylingFields = feature.customStylesByFields;

  const size = calculateSize(feature);
  const zIndex = size > 0 ? 10000 / size : 0;

  layerStyle.setZIndex(zIndex);

  const customStylingField = customStylingFields?.filter(c => customStylesByField[c.id]?.[c.value])?.at(-1);
  if (customStylingField) {
    const customColor = customStylesByField[customStylingField.id]?.[customStylingField.value];

    if (customColor) {
      const currentStrokeWidth = layerStyle.getStroke().getWidth();
      layerStyle.setStroke(
        new Stroke({
          width: currentStrokeWidth!,
          color: customColor,
        })
      );

      return layerStyle;
    }
  }

  const styleId = map.backgroundMap.get(Number(feature.get('styleId')));

  if (map.useCustomStyles && styleId) {
    const customStyle = map.backgroundMap.get(Number(feature.get('styleId')));
    customStyle?.setStroke(layerStyle.getStroke());
    customStyle?.setZIndex(zIndex);

    return customStyle;
  }

  return defaultToLayer ? layerStyle : undefined;
}

export { getCustomStyle };
