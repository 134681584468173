import { Stack, Typography } from '@mui/material';

import DxfFileContents from '../../models/DxfFileContents';

const FieldsMap: { [key: number]: string } = {
  60: 'NR C.F.',
  61: 'NR CAD',
  62: 'NR TOP',
};

const ReportTypeNameMap: { [key: number]: string } = {
  1: 'Imobile',
  2: 'Constructii',
  4: 'Patrimonii',
};

type Props = {
  fieldsToLink: number[];

  reportType: number;

  dxfFileContents: DxfFileContents[];
};

const FinalizeImport = ({ fieldsToLink, dxfFileContents, reportType }: Props) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Typography variant="h5">Rezumat import</Typography>
      <Typography variant="body1">{`Vor fi importate ${dxfFileContents.reduce(
        (acc, curr) => acc + curr.polygons!,
        0
      )} poligoane si ${dxfFileContents.reduce((acc, curr) => acc + curr.texts!, 0)} texte`}</Typography>
      <Typography variant="body1">{`Poligoanele vor fi importate ca si: ${ReportTypeNameMap[reportType]}`}</Typography>
      <Typography variant="body1">
        {fieldsToLink.length > 0
          ? `Valorile text for fi setate in urmatoarele campuri: ${fieldsToLink.map(f => FieldsMap[f]).join(', ')}`
          : 'Niciun camp nu a fost selectat pentru valorile text'}
      </Typography>
    </Stack>
  );
};

export default FinalizeImport;
