import { useCallback, useContext, useEffect, useState } from 'react';

import { Coordinate } from 'ol/coordinate';
import { Circle, Point, Polygon, SimpleGeometry } from 'ol/geom';
import { Type } from 'ol/geom/Geometry';
import { fromCircle, makeRegular } from 'ol/geom/Polygon';
import { Draw, Snap } from 'ol/interaction';
import { DrawEvent, GeometryFunction } from 'ol/interaction/Draw';
import Projection from 'ol/proj/Projection';
import { register } from 'ol/proj/proj4';
import { Vector as VectorSource } from 'ol/source';
import proj4 from 'proj4';

import CreateBuilding from '../../components/Building/CreateBuilding';
import CreateCamin from '../../components/Camin/CreateCamin';
import CreateConducta from '../../components/Conducta/CreateConducta';
import CreateMeasurement from '../../components/Measurement/CreateMeasurement';
import CreateParcel from '../../components/Parcel/CreateParcel';
import CreatePatrimoniu from '../../components/Patrimoniu/CreatePatrimoniu';
import BlankMeasurementLink from '../../components/Report/BlankMeasurementLink';
import MultipleReportSelection from '../../components/Report/MultipleReportSelection';
import ReportView from '../../components/Report/ReportView';
import CreateTerrain from '../../components/Terrain/CreateTerrain';
import { ReportTypes } from '../../models/ReportType';
import SGDEFeature, { FeatureType } from '../../models/feature/sgde-feature';
import { AreaMeasurement } from '../../models/measurement.model';
import LightReport from '../../models/report-light.model';
import { getResource } from '../../store/Fetch';
import { reportsApiUrl } from '../../store/settings/Local';
import { useGetBackgroundStyles } from '../../store/slices/backgroundStylesApi';
import { useAddMeasurement, useAddMeasurementFields } from '../../store/slices/measurementsApi';
import { useCurrentUser } from '../../store/slices/usersApi';
import useStore from '../../store/useStore';
import { ZoomLevel } from '../../utils/Constants';
import { DataContext } from '../Data/DataContext.tsx';
import { NotificationContext } from '../Notification/Notification.tsx';

import OpenLayersMap from '../../models/map/open-layers-map.model';
import { creationModes } from '../Map/CreationModes';
import MapActions from '../Map/MapActions';
import Dialog from '../Modal/Dialog';
import StreetView from './Streeview';

const OpenLayersMapView = () => {
  const { dispatch: notify } = useContext(NotificationContext);
  const [map, setMap] = useState<OpenLayersMap>();
  const [draw, setDraw] = useState<Draw>();
  const [snap, setSnap] = useState<Snap>();
  const [vectorSource, setVectorSource] = useState<VectorSource>();
  const [creationMode, setCreationMode] = useState<creationModes>();
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showNewReportDialog, setShowNewReportDialog] = useState<boolean>(false);
  const settings = useStore(state => state.settings);
  const viewSettings = useStore(state => state.viewSettings);
  const { data: currentUser } = useCurrentUser();
  const { setEdit, saveEdit } = useContext(DataContext);
  const [addMeasurement, { isSuccess: isMeasurementAdded }] = useAddMeasurement();
  const [addMeasurementFields, { isSuccess: isMeasurementFieldsAdded }] = useAddMeasurementFields();
  const [features, setFeatures] = useState<SGDEFeature[]>([]);
  const [streetview, setStreetview] = useState<SGDEFeature>();

  const { data: backgroundStyles } = useGetBackgroundStyles();

  proj4.defs(
    'EPSG:3844',
    `+proj=sterea +lat_0=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs`
  );

  register(proj4);

  // this is Stereo 70
  const epsg3844 = new Projection({
    code: 'EPSG:3844',
    extent: [116394.92, 226748.13, 975734.46, 771888.93],
  });

  useEffect(() => {
    map?.setZoom(viewSettings.mapZoom || ZoomLevel.Default);
  }, [viewSettings]);

  useEffect(() => {
    if (!backgroundStyles) return;

    map?.setBackgroundOptions(backgroundStyles);
    map?.reloadAllMeasurementsLayers();
  }, [backgroundStyles]);

  useEffect(() => {
    if (settings && settings.currentLocationSetting) {
      const currentLocationSetting: AreaMeasurement = settings?.currentLocationSetting;
      if (currentLocationSetting.customCoordinatesArray && currentLocationSetting.customCoordinatesArray.length > 0) {
        map?.setCenter([
          currentLocationSetting.customCoordinatesArray[0][0].x,
          currentLocationSetting.customCoordinatesArray[0][0].y,
        ]);

        map?.setZoom(ZoomLevel.Search);

        setTimeout(() => {
          map?.addFeatureToSelection(
            currentLocationSetting.reportTypeId as ReportTypes,
            currentLocationSetting.id,
            true
          );
        }, 0);
      } else {
        notify?.({
          type: 'info',
          message: 'Imobilul nu are masuratoare efectuata',
        });
      }
    } else if (settings.lastLocation) {
      const splitCoordinates = settings.lastLocation.split(',').map(c => Number(c));
      map?.setCenter([splitCoordinates[0], splitCoordinates[1]]);
    }

    map?.showBackgroundType(settings.showGroundOverlays || false, settings.backgroundType);
    map?.showCustomStyles(settings.isPublic || settings?.showCustomStyles);
    map?.showMeasurementLayers(settings.reportTypes as ReportTypes[]);
    map?.showStreeview(settings.showStreetView, settings.streetViewYears);
  }, [settings]);

  const handleMapSettings = useCallback(async () => {
    const center = await getResource(`${reportsApiUrl}/configuration/maps/`);
    const centerAsPoint = new Point([center.long, center.lat]);
    centerAsPoint.transform('EPSG:4326', 'EPSG:3844');
    const projectedCenterCoordinate = centerAsPoint.getCoordinates();

    if (settings.lastLocation) {
      const splitCoordinates = settings.lastLocation.split(',').map(c => Number(c));
      map?.setCenter([splitCoordinates[0], splitCoordinates[1]]);
      map?.setZoom(settings?.publicPageDefaultZoom || ZoomLevel.Default);
    } else {
      map?.setCenter([projectedCenterCoordinate[0], projectedCenterCoordinate[1]]);
      map?.setZoom(settings?.publicPageDefaultZoom || ZoomLevel.Default);
    }

    map?.setDblClickHandler(features => {
      if (features.length == 1) {
        const feature = features[0];
        const featureType = feature.get('type');
        if (featureType === FeatureType.Measurement) {
          onShowReportDialog([feature]);
        } else if (featureType === FeatureType.Streetview) {
          setStreetview(feature);
        }
      } else {
        onShowReportDialog(features);
      }
    });
  }, [map, settings?.publicPageDefaultZoom, settings?.lastLocation]);

  const handleListener = useCallback(
    (type: string, feature: SGDEFeature) => {
      if (!map) return;

      if (type === 'start-edit') {
        map.editFeature(feature);
      }

      if (type === 'save-edit') {
        const editedGeometry = saveEdit?.();
        if (!editedGeometry || !editedGeometry.reportId) return;

        const id = editedGeometry.reportId;
        const areaMeasurementId = editedGeometry.areaMeasurementId;
        const measurement = editedGeometry.getAreaMeasurement();
        const fields = [
          {
            reportFieldDefinitionId: 36,
            value: String(true),
          },
          {
            reportFieldDefinitionId: 1,
            value: String(measurement.area),
          },
        ];

        addMeasurement({ id: areaMeasurementId!, measurement });
        addMeasurementFields({ id, fields });
        map.endEditFeature();
      }
    },
    [saveEdit, setEdit, map, addMeasurement, addMeasurementFields]
  );

  useEffect(() => {
    if (map) {
      return;
    }

    if (settings.isReady) {
      const newMap = new OpenLayersMap(
        'map',
        [0, 0],
        2,
        epsg3844,
        undefined,
        undefined,
        !settings.isPublic,
        settings.isPublic
      );

      setVectorSource(newMap.vectorSource);
      setMap(newMap);
    }
  }, [currentUser?.roles, epsg3844, map, settings.isPublic, settings.isReady]);

  useEffect(() => {
    if (!map) return;

    map.measurementsZoom = settings?.publicPageDefaultZoom || ZoomLevel.Default;
  }, [map, settings?.publicPageDefaultZoom]);

  useEffect(() => {
    if (!map) return;

    (async () => {
      await handleMapSettings();
    })();
  }, [map, settings?.publicPageDefaultZoom]);

  useEffect(() => {
    if (!map) return;

    map.setListener(handleListener);
  }, [map, handleListener]);

  useEffect(() => {
    if (isMeasurementAdded && isMeasurementFieldsAdded) {
      closeDialog();
      notify?.({ severity: 'success', message: 'Masuratoare actualizata' });
    }
  }, [isMeasurementAdded, isMeasurementFieldsAdded]);

  const onAddAction = (creationMode: creationModes) => {
    setCreationMode(creationMode);

    if (creationMode === creationModes.NONE) {
      if (snap) {
        map?.inner.removeInteraction(snap);
      }

      if (draw) {
        map?.inner.removeInteraction(draw);
      }

      return;
    }

    let drawingType: Type;
    let geometryFunction;
    if (creationMode === creationModes.CONDUCTA) {
      drawingType = 'LineString';
    } else if (creationMode === creationModes.CAMIN) {
      drawingType = 'Circle';
      geometryFunction = function (coordinates: Coordinate[], geometry: SimpleGeometry) {
        const center = coordinates[0];
        const last = coordinates[coordinates.length - 1];
        const dx = center[0] - last[0];
        const dy = center[1] - last[1];
        const radius = 0.5;
        const rotation = Math.atan2(dy, dx);
        const outer = fromCircle(new Circle(center), 4);
        const inner = fromCircle(new Circle(center), 16);
        makeRegular(inner, center, radius / 2, 0);

        makeRegular(outer, center, radius, rotation);

        if (!geometry) {
          geometry = new Polygon([outer.getCoordinates()[0], inner.getCoordinates()[0]]);
        } else {
          geometry.setCoordinates([outer.getCoordinates()[0], inner.getCoordinates()[0]]);
        }
        return geometry;
      } as GeometryFunction;
    } else {
      drawingType = 'Polygon';
    }

    const newDraw = new Draw({
      source: vectorSource,
      type: drawingType,
      geometryFunction,
    });

    map?.inner.addInteraction(newDraw);
    setDraw(newDraw);

    const newSnap = new Snap({ source: vectorSource });
    setSnap(newSnap);
    map?.inner.addInteraction(newSnap);

    newDraw.on('drawend', (ev: DrawEvent) => {
      setFeatures([new SGDEFeature(ev.feature)]);

      setShowNewReportDialog(true);
      map?.inner.removeInteraction(newDraw);
      map?.inner.removeInteraction(newSnap);
    });
  };

  const closeDialog = () => {
    setShowNewReportDialog(false);
    setShowReportDialog(false);
    setCreationMode(creationModes.NONE);
    setFeatures([]);
  };

  const onShowReportDialog = useCallback(
    (features: SGDEFeature[]): void => {
      setFeatures(features);
      setShowReportDialog(true);
    },
    [setShowNewReportDialog, setFeatures]
  );

  const getCreateReportDialogContent = useCallback(
    (features: SGDEFeature[]) => {
      if (!map) return <></>;
      let singleFeature: SGDEFeature | undefined;

      if (features.length) {
        singleFeature = features[0];
      }

      if (!singleFeature) {
        return <></>;
      }

      switch (creationMode) {
        case creationModes.BUILDING:
          return (
            <CreateBuilding
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        case creationModes.TERAIN:
          return (
            <CreateTerrain
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        case creationModes.MEASUREMENT:
          return (
            <CreateMeasurement
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        case creationModes.PATRIMONIU:
          return (
            <CreatePatrimoniu
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        case creationModes.CONDUCTA:
          return (
            <CreateConducta
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        case creationModes.CAMIN:
          return (
            <CreateCamin
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        case creationModes.PARCELA:
          return (
            <CreateParcel
              map={map}
              showReportDialog={onShowReportDialog}
              feature={singleFeature}
              closeDialog={closeDialog}
            />
          );
        default:
          return <></>;
      }
    },
    [creationMode, setShowReportDialog]
  );

  const getReportViewDialogContent = useCallback(() => {
    if (!features.length || !map) return <></>;

    if (features.length === 1) {
      const feature = features[0];
      const reportId = feature.reportId;
      return reportId ? (
        <ReportView
          map={map}
          feature={feature}
          areaMeasurementId={feature.areaMeasurementId}
          reportIds={[reportId]}
          closeDialog={() => setShowReportDialog(false)}
        />
      ) : (
        <BlankMeasurementLink map={map} feature={feature} closeDialog={() => setShowReportDialog(false)} />
      );
    } else {
      return (
        <MultipleReportSelection
          map={map}
          selectedReports={features.map(
            f =>
              ({
                reportId: f.reportId,
                areaMeasurementId: f.areaMeasurementId,
                measurementId: f.measurementId,
                reportTypeId: f.reportTypeId,
                feature: f,
              }) as LightReport
          )}
          closeDialog={() => setShowReportDialog(false)}
        />
      );
    }
  }, [map, features, setShowReportDialog]);

  return (
    <>
      <div style={{ height: '100%', flex: '1' }} id="map" className="map"></div>
      {!settings.isPublic && <MapActions addAction={onAddAction} />}
      <StreetView feature={streetview} />
      {!settings.isPublic && (
        <Dialog maxWidth="md" onClose={closeDialog} open={showReportDialog || showNewReportDialog}>
          {showReportDialog ? getReportViewDialogContent() : getCreateReportDialogContent(features)}
        </Dialog>
      )}
    </>
  );
};

export default OpenLayersMapView;
