import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ReportType, findReportTypeById } from '../../models/ReportType';
import { BackgroundTypes } from '../../models/map/open-layer/map-utils';
import Settings from '../../models/settings/settings.model';
import { useUpdateSettings } from '../../store/slices/settingsApi';
import { useStreetViewYears } from '../../store/slices/streeViewApi';
import { useCurrentUser } from '../../store/slices/usersApi';
import useStore from '../../store/useStore';
import { ModalContext } from '../Modal/ModalContext';
import { NotificationContext } from '../Notification/Notification';

const MapPreferencesPane = (props: { setMapPreferencesState?: (settings: Settings) => void }) => {
  const { setMapPreferencesState } = props;

  const { isSuccess } = useCurrentUser();
  const { data: streetViewYears } = useStreetViewYears(undefined, {
    skip: !isSuccess,
  });

  const setSettings = useStore(state => state.setSettings);
  const settings = useStore(state => state.settings);

  const { dispatch: notify } = useContext(NotificationContext);
  const [updateSettings, { isSuccess: saveSettingsIsSuccess, isError: saveSettingsIsError }] = useUpdateSettings();

  const [localSettings, setLocalSettings] = useState<Settings>(settings);

  const { dispatch: modal } = useContext(ModalContext);

  const handleReportTypesChange = (event: SelectChangeEvent<number[]>) => {
    const options = event.target.value as number[];
    setLocalSettings({ ...localSettings, reportTypes: options });
  };

  const handleShowStreetViewChange = (event: ChangeEvent<HTMLInputElement>) => {
    const showStreetView = event.target.checked;
    setLocalSettings({ ...localSettings, showStreetView });
  };

  const handleShowGroundOverlaysChange = (event: ChangeEvent<HTMLInputElement>) => {
    const showGroundOverlays = event.target.checked;
    setLocalSettings({ ...localSettings, showGroundOverlays });
  };

  const handleShowCustomStylesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const showCustomStyles = event.target.checked;
    setLocalSettings({ ...localSettings, showCustomStyles });
  };

  const handleYearsChange = (event: SelectChangeEvent<number[]>) => {
    const options = event.target.value as number[];
    setLocalSettings({ ...localSettings, streetViewYears: options });
  };

  const handleBackgroundTypeChange = (event: SelectChangeEvent<string>) => {
    const backgroundType = event.target.value as BackgroundTypes;
    setLocalSettings({ ...localSettings, backgroundType });
  };

  const onSave = async () => {
    updateSettings(localSettings);
  };

  useEffect(() => {
    if (saveSettingsIsSuccess) {
      setSettings(localSettings);
      notify?.({ severity: 'success', message: 'Setarile s-au salvat cu succes' });
      modal?.({ type: 'close' });
    }

    if (saveSettingsIsError) {
      notify?.({ severity: 'error', message: 'Eroare la salvarea setarilor' });
    }
  }, [saveSettingsIsSuccess, saveSettingsIsError]);

  useEffect(() => {
    setMapPreferencesState?.(localSettings);
    if (!setMapPreferencesState)
      modal?.({
        type: 'actions',
        actions: [
          {
            content: (
              <Button startIcon={<SaveIcon />} key="addPatrimoniu" onClick={onSave} color="primary">
                Salveaza
              </Button>
            ),
          },
        ],
      });
  }, [localSettings]);

  const getOptionLabel = (selected: any) => {
    return selected
      .map((s: any) => {
        const option = findReportTypeById(s);
        return option ? option : null;
      })
      .filter((o: any) => o)
      .join(', ');
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="measurements-to-show-label">Masuratori afisate</InputLabel>
            <Select
              fullWidth
              labelId="measurements-to-show-label"
              input={<OutlinedInput label="Masuratori afisate" />}
              multiple
              value={localSettings.reportTypes}
              onChange={handleReportTypesChange}
              renderValue={selected => getOptionLabel(selected)}
            >
              {Object.entries(ReportType)
                .filter(([, value]) => value !== ReportType.Proprietar)
                .map(([type, id]) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={localSettings.reportTypes.indexOf(id) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="background-type-to-show-label">Tip fundal</InputLabel>
            <Select
              fullWidth
              labelId="background-type-to-show-label"
              input={<OutlinedInput label="Tip fundal" />}
              value={localSettings.backgroundType}
              onChange={handleBackgroundTypeChange}
            >
              <MenuItem key="ortofotoplan" value="ortofotoplan">
                <ListItemText primary="Ortofotoplan" />
              </MenuItem>
              <MenuItem key="bing" value="bing">
                <ListItemText primary="Bing" />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="street-view-show-label">Afisare streetview</InputLabel>
            <Select
              fullWidth
              labelId="street-view-show-label"
              input={<OutlinedInput label="Afisare streetview" />}
              multiple
              label="Afisare streetview"
              value={localSettings.streetViewYears}
              onChange={handleYearsChange}
              renderValue={selected => selected.join(', ')}
            >
              {streetViewYears?.map(year => (
                <MenuItem key={year} value={year}>
                  <Checkbox checked={localSettings.streetViewYears.indexOf(year) > -1} />
                  <ListItemText primary={year} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} display="flex">
          <FormControlLabel
            control={
              <Switch
                checked={localSettings.showStreetView}
                onChange={handleShowStreetViewChange}
                name="showStreetView"
                color="primary"
              />
            }
            label="Afiseaza street view"
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={12} lg={12} columnSpacing={4}>
        <Grid item xs={6} display="flex">
          <FormControlLabel
            control={
              <Switch
                checked={localSettings.showGroundOverlays}
                onChange={handleShowGroundOverlaysChange}
                name="showGroundOverlays"
                color="primary"
              />
            }
            label="Afiseaza ortofotoplan"
          />
        </Grid>
        <Grid item xs={6} display="flex">
          <FormControlLabel
            control={
              <Switch
                checked={localSettings.showCustomStyles}
                onChange={handleShowCustomStylesChange}
                name="showCustomStyles"
                color="primary"
              />
            }
            label="Afiseaza urbanism"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MapPreferencesPane;
