import { useCallback, useContext, useEffect, useState } from 'react';

import { Alert, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import DxfFileContents from '../../models/DxfFileContents';
import { NotificationContext } from '../../primitives/Notification/Notification';
import { useParseDxfFiles } from '../../store/slices/dxfApi';

type Props = {
  dxfFiles: File[];

  selectedFieldsToLink: number[];

  selectedReportType: number;

  onOptionsChange: (reportType: number, fieldToLink: number[]) => void;

  onDxfFileContentsChange: (dxfFileContents: DxfFileContents[]) => void;
};

const ImportOptions = ({
  dxfFiles,
  onOptionsChange,
  selectedFieldsToLink,
  selectedReportType,
  onDxfFileContentsChange,
}: Props) => {
  const [textEntities, setTextEntities] = useState(0);
  const [polygonEntities, setPolygonEntities] = useState(0);
  const [parseDxfFiles, { isError, data }] = useParseDxfFiles();
  const [fieldsToLink, setFieldsToLink] = useState<number[]>(selectedFieldsToLink);
  const [reportType, setReportType] = useState<number>(selectedReportType);
  const { dispatch: notify } = useContext(NotificationContext);

  useEffect(() => {
    parseDxfFiles(dxfFiles);
  }, [dxfFiles]);

  useEffect(() => {
    if (isError) {
      notify?.({ severity: 'error', message: 'Eroare la incarcarea fisierelor dxf' });
      return;
    }

    const parsedDxfFiles = data?.filter(f => !f.hasError);
    setTextEntities(parsedDxfFiles?.reduce((acc, curr) => acc + curr.texts!, 0) || 0);
    setPolygonEntities(parsedDxfFiles?.reduce((acc, curr) => acc + curr.polygons!, 0) || 0);
    onDxfFileContentsChange(data!);
  }, [data, isError]);

  const onFieldOptionChange = useCallback(
    (fieldId: number) => {
      let newFieldsToLink = [];
      if (fieldsToLink.includes(fieldId)) {
        fieldsToLink.splice(fieldsToLink.indexOf(fieldId), 1);
        newFieldsToLink = [...fieldsToLink];
      } else {
        newFieldsToLink = [...fieldsToLink, fieldId];
      }

      setFieldsToLink(newFieldsToLink);
      onOptionsChange(reportType, newFieldsToLink);
    },
    [fieldsToLink, reportType]
  );

  const onReportTypeChange = useCallback(
    (selectedReportType: number) => {
      setReportType(selectedReportType);
      onOptionsChange(selectedReportType, fieldsToLink);
    },
    [fieldsToLink, reportType]
  );

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      {polygonEntities > 0 && (
        <Alert severity="info">Sunt {polygonEntities} poligoane detectate in fisierele incarcate</Alert>
      )}
      {textEntities > 0 && <Alert severity="info">Sunt {textEntities} texte detectate in fisierele incarcate</Alert>}
      {textEntities > 0 && (
        <>
          <Typography variant="h6">Selectati tipul de raport care se va crea automat</Typography>
          <div>
            <FormControlLabel
              label="Imobil"
              control={<Checkbox checked={reportType === 1} onClick={() => onReportTypeChange(1)}></Checkbox>}
            ></FormControlLabel>
            <FormControlLabel
              label="Constructie"
              control={<Checkbox checked={reportType === 2} onClick={() => onReportTypeChange(2)}></Checkbox>}
            ></FormControlLabel>
            <FormControlLabel
              label="Patrimoniu"
              control={<Checkbox checked={reportType === 4} onClick={() => onReportTypeChange(4)}></Checkbox>}
            ></FormControlLabel>
          </div>
          {reportType === 1 && (
            <>
              <Typography variant="h6">Selectati camp pentru import text</Typography>
              <div>
                <FormControlLabel
                  label="NR C.F."
                  control={
                    <Checkbox checked={fieldsToLink.includes(51)} onClick={() => onFieldOptionChange(51)}></Checkbox>
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label="NR CAD"
                  control={
                    <Checkbox checked={fieldsToLink.includes(61)} onClick={() => onFieldOptionChange(61)}></Checkbox>
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label="NR TOP"
                  control={
                    <Checkbox checked={fieldsToLink.includes(62)} onClick={() => onFieldOptionChange(62)}></Checkbox>
                  }
                ></FormControlLabel>
              </div>
            </>
          )}
          {reportType === 4 && (
            <>
              <Typography variant="h6">Selectati camp pentru import text</Typography>
              <div>
                <FormControlLabel
                  label="NR C.F."
                  control={
                    <Checkbox checked={fieldsToLink.includes(60)} onClick={() => onFieldOptionChange(60)}></Checkbox>
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label="CAD/TOP"
                  control={
                    <Checkbox checked={fieldsToLink.includes(52)} onClick={() => onFieldOptionChange(52)}></Checkbox>
                  }
                ></FormControlLabel>
              </div>
            </>
          )}
        </>
      )}
    </Stack>
  );
};

export default ImportOptions;
