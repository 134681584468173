import { ChangeEvent, useContext, useEffect, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';

import { DocumentLibrary } from '../../models/document-library.model';
import { Gallery } from '../../models/gallery.model';
import { Inventory } from '../../models/inventory.model';
import OpenLayersMap from '../../models/map/open-layers-map.model';
import { ReportField } from '../../models/report-field.model';
import { Report } from '../../models/report.model';
import { NotificationContext } from '../../primitives/Notification/Notification';
import { ProgressContext } from '../../primitives/Progress/Progress';
import { useUpdateDocuments } from '../../store/slices/documentsApi';
import { useUpdateGallery } from '../../store/slices/galleryApi';
import { useCreateInventory } from '../../store/slices/inventoryApi';
import { useCreateReportFields, useUpdateReport } from '../../store/slices/reportsApi';
import DynamicForm from '../Form/DynamicForm/DynamicForm';

type Props = {
  reports: Report[];
  closeDialog: () => void;
  map: OpenLayersMap;
};

const EditDialog = ({ reports, closeDialog, map }: Props) => {
  const { dispatch: progress } = useContext(ProgressContext);
  const { dispatch: notify } = useContext(NotificationContext);
  const [additionalReportFields, setAdditionalReportFields] = useState<ReportField[]>([]);
  const [name, setName] = useState(reports[0].name);
  const [photos, setPhotos] = useState<{ [key: string]: Gallery }>({});
  const [documents, setDocuments] = useState<{ [key: string]: DocumentLibrary }>({});
  const [inventory, setInventory] = useState<Inventory>();
  const [error, setError] = useState(!reports[0].name);
  const [isValid, setIsValid] = useState(false);
  const [updateReport] = useUpdateReport();
  const [createReportFields, { isSuccess, isError }] = useCreateReportFields();
  const [createInventory] = useCreateInventory();
  const [updateGallery] = useUpdateGallery();
  const [updateDocuments] = useUpdateDocuments();
  const isMultiEdit = reports.length > 1;

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
      notify?.({ severity: 'success', message: 'Fisa editata cu succes' });
      progress?.('hide');
      map.reloadAllMeasurementsLayers();
    }

    if (isError) {
      notify?.({ severity: 'error', message: 'Eroare la editarea fisei' });
      progress?.('hide');
    }
  }, [isSuccess, isError]);

  const onSave = async () => {
    Object.values(photos).forEach(photo => updateGallery(photo));
    Object.values(documents).forEach(document => updateDocuments(document));
    console.log('inventory', inventory);
    if (inventory) {
      createInventory({ inventory, reportId: reports[0].id! });
    }
    reports.forEach(report => createReportFields({ reportFields: additionalReportFields, reportId: report.id! }));
    if (reports.length === 1) {
      updateReport({ ...reports[0], name });
    }
  };
  const onNameChange = ({ target: { value: name } }: ChangeEvent<HTMLInputElement>) => {
    setError(!name?.length);
    setName(name);
  };

  return (
    <>
      <DialogTitle>Editeaza fisa</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {!isMultiEdit && (
              <TextField
                fullWidth
                error={error}
                value={name}
                required
                onChange={onNameChange}
                variant="outlined"
                margin="normal"
                label="Nume"
              />
            )}
            <DynamicForm
              reports={reports}
              reportTypeId={reports[0].reportTypeId}
              onValidityChange={setIsValid}
              onValueChanged={setAdditionalReportFields}
              onGalleryChanged={(reportFieldDefinitionId: number, gallery: Gallery) => {
                setPhotos({ ...photos, [reportFieldDefinitionId]: gallery });
              }}
              onDocumentsChange={(reportFieldDefinitionId, documentLibrary) => {
                setDocuments({ ...documents, [reportFieldDefinitionId]: documentLibrary });
              }}
              onMultiYearChange={setInventory}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<SaveIcon />}
          disabled={!isValid || error}
          key="editPatrimoniu"
          onClick={onSave}
          color="primary"
        >
          Salveaza
        </Button>
      </DialogActions>
    </>
  );
};

export default EditDialog;
