import { AuthenticationResult } from '@azure/msal-browser';
import Feature from 'ol/Feature';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import VectorImageLayer from 'ol/layer/VectorImage';
import { bbox } from 'ol/loadingstrategy';
import { Projection } from 'ol/proj';
import { Vector } from 'ol/source';
import VectorSource from 'ol/source/Vector';

import { loginRequest } from '../../../authConfig';
import { msalInstance } from '../../../main';
import { ReportTypes } from '../../ReportType.ts';
import { getCustomStyle } from '../../feature/feature-styles.ts';
import SGDEFeature from '../../feature/sgde-feature';

import OpenLayersMap from '../open-layers-map.model';
import { reportTypeStyles } from './map-styles';
import { mapBoundsFromExtent } from './map-utils';

interface MeasurementsLayerOptions {
  sourceUrl: string;

  reportType: ReportTypes;

  isPublic: boolean;

  zIndex?: number;
}

class MeasurementsLayer extends VectorImageLayer<VectorSource<Geometry>> {
  vectorSource: Vector;

  // private _style?: Style;

  constructor(
    private map: OpenLayersMap,
    private options: MeasurementsLayerOptions
  ) {
    const style = reportTypeStyles[options.reportType];
    super({
      style,
      zIndex: options.zIndex,
    });

    this.vectorSource = new Vector({
      loader: (extent: Extent, resolution: number, projection: Projection) =>
        this.loader(extent, resolution, projection),
      format: new GeoJSON(),
      strategy: bbox,
    });

    this.setSource(this.vectorSource);
  }

  // write a method that calculates fibonacci numbers until a given max value
  // and returns them in an array
  // the method should be called fibonacci and should have one parameter
  // the parameter should be the max value
  public async loader(extent: Extent, _: number, projection: Projection) {
    const url = this.options.sourceUrl;

    const mapBounds = mapBoundsFromExtent(extent);

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: await this.getToken(),
      },
      body: JSON.stringify({
        reportType: this.options.reportType,
        public: this.options.isPublic,
        boundingBox: mapBounds,
      }),
    });

    if (!res.body) return;
    const reader = res.body?.getReader();

    const textDecoder = new TextDecoder('utf-8');
    let buffer = '';
    const format = this.vectorSource.getFormat();
    while (true) {
      const { value: chunk, done } = await reader.read();
      buffer += textDecoder.decode(chunk || new Uint8Array(), { stream: !done });

      let lineEnd;
      while ((lineEnd = buffer.indexOf('#')) !== -1) {
        const line = buffer.slice(0, lineEnd);
        buffer = buffer.slice(lineEnd + 1);

        const data = JSON.parse(line);
        const featureLike = format?.readFeature(data, { featureProjection: projection, dataProjection: projection });

        if (featureLike) {
          const feature = featureLike as Feature;
          const backgroundStyle = getCustomStyle(this.map, new SGDEFeature(feature), true);

          if (backgroundStyle) {
            feature.setStyle(backgroundStyle);
          }

          this.vectorSource.addFeature(feature as Feature);

          if (this.map.checkIsFeatureSelected(feature.getId() as number)) {
            this.map.addFeatureToSelection(feature.get('reportTypeId') as ReportTypes, feature.getId() as number, true);
          }
        }
      }

      if (done) {
        break;
      }
    }
  }

  private async getToken(): Promise<string> {
    const account = msalInstance.getActiveAccount();
    const response = !account
      ? ({} as AuthenticationResult)
      : await msalInstance.acquireTokenSilent({ ...loginRequest, account });

    // If we have a token set in state, let's assume that we should be passing it.
    if (response.accessToken) {
      return `Bearer ${response.accessToken}`;
    }

    return '';
  }
}

export default MeasurementsLayer;
